import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { url } from './url';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [itemData, setItemData] = useState([]);
  const [category, setCategory] = useState([]);

  const loadItems = async () => {
    try {
      const response = await axios.get(url + '/load_cards');
      setItemData(response.data);
      const uniqueCategoriesSet = new Set(response.data.map(item => !item.category ? null : item.category));
      const uniqueCategoriesArray = Array.from(uniqueCategoriesSet);
      setCategory(uniqueCategoriesArray);
    } catch (error) {
      console.error('Failed to load items:', error);
    }
  };
  const deleteCard = async (id) => {
    try {
      await axios.delete(`${url}/delete_card/${id}`);
      // Remove the deleted card from the itemData state
      setItemData(prevItemData => prevItemData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Failed to delete card:', error);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <DataContext.Provider value={{ itemData, category, loadItems, deleteCard }}>
      {children}
    </DataContext.Provider>
  );
};
