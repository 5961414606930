import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PlaceIcon from '@mui/icons-material/Place';
import CategoryIcon from '@mui/icons-material/Category';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import '../App.css'

export default function SwipeableTemporaryDrawer(props) {
  const navigate = useNavigate();
  const { category, onCategoryChange, open, onClose, onOpen } = props;
  const { status, logout } = React.useContext(AuthContext);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
      className='tf'
    >
      <List>
      {(category || []).sort().map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => onCategoryChange(text)}>
              <ListItemIcon>
                {/* <CategoryIcon sx={{color:'white'}}/> */}
              </ListItemIcon>
              <h3 className='tf'>{text}</h3>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem key={'LOCATIONS'} disablePadding>
          <ListItemButton onClick={() => navigate('/locations')}>
            <ListItemIcon>
              <PlaceIcon sx={{color:'white'}}/>
            </ListItemIcon>
            <h3  >LOCATIONS</h3> 
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={'ADMIN'} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SupervisorAccountIcon sx={{color:'white'}}/>
            </ListItemIcon>
            {!status && <h3 onClick={() => navigate('/admin')}>ADMIN </h3>}
            {status && <h3 onClick={logout} >LOGOUT</h3>}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={'left'}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: { backgroundColor: '#1d2127', color:'white' }
      }}
    >
      {list('left')}
    </SwipeableDrawer>
  );
}
