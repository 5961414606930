import './App.css';
import Home from './pages/Home'
import { AuthContextProvider } from "./AuthContext";
import { DataProvider } from './DataContext';
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import Modal from './components/Modal'
import LoginForm from './pages/LoginForm'
import axios from 'axios';
import Location from './pages/Location';

function App() {
  axios.defaults.withCredentials = true;
	
  return (
    <div className="App">
      {/* <header className="App-header"> 
      </header> */}
      <AuthContextProvider>
        <DataProvider>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/locations" element={<Location />}></Route>
          <Route path="/admin" element={<LoginForm />}></Route>
          <Route
								path="/upload"
								element={
                  <ProtectedRoute >
										<Modal element={(props) => <Modal {...props} />}/>
									</ProtectedRoute>
								}
                />
        </Routes>
        </DataProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
