import * as React from 'react';
import {Box,Button,Modal} from '@mui/material';
import InputFileUpload from './FileUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth:'80%',
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:'15px'
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUploadSuccess = () => {
    setOpen(false);
  };

  return (
    <div> 
      <Button onClick={handleOpen}>
        <h2 className='tf'>UPLOAD</h2>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <InputFileUpload handleUploadSuccess={handleUploadSuccess}/>
        </Box>
      </Modal>
    </div>
  );
}