import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button, CircularProgress, Typography } from '@mui/material';
import { AuthContext } from '../AuthContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  width: '90%',
  color:'white',
  backgroundColor:'#282c34',
  textAlign:'center',
  border: 'none',
  outline: 'none',
  borderRadius:'15px',
  // overflowY:'auto',
  '@media (min-width: 600px)': {
    width: '80%',
  },
  '@media (min-width: 900px)': {
    width: '60%',
  },
  '@media (min-width: 1200px)': {
    width: '35%',
  },
};

const imageStyle = {
  width: '100%',
  height: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
};

export default function TransitionsModal(props) {
  const { open, handleClose, selectedImage, deleteCard } = props;
  const { status } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  const handleDelete = () => {
    deleteCard(selectedImage.id);
    handleClose();
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        loading='lazy'
      >
        <Fade in={open}>
          <Box sx={style}>
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <img
              src={selectedImage.url}
              alt="Selected"
              style={{ ...imageStyle, display: loading ? 'none' : 'block' }}
              onLoad={handleImageLoad}
            />
            {status && (
              <Box sx={{textAlign: 'center' }}>
                <Button variant='contained' color='error' style={{ zIndex: 1, margin: '15px', color:'white' }} onClick={handleDelete}>
                  <Typography sx={{color:'white'}}>Delete</Typography>
                </Button>
              </Box>
            )}
             {/* {selectedImage.category && <div><Typography variant="p" component="h2" sx={{p:2}}>{selectedImage.category}</Typography></div>} */}
            {selectedImage.title && <Typography variant="h6" component="p" sx={{p:2, textAlign:'left', fontWeight:'bold'}}>{selectedImage.title.toUpperCase()}</Typography>}
            {selectedImage.description && <Typography variant="p" component="p" sx={{p:2, textAlign:'left'}}>{selectedImage.description}</Typography>}

          </Box>
          
        </Fade>
      </Modal>
    </div>
  );
}
