import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import {url} from '../url'
// import PrograssCircle from '../components/PrograssCircle'
import { DataContext } from '../DataContext';
import { Box, CircularProgress, TextField } from '@mui/material';
import HorizontalStepper from './Steper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload(props) {
  const {handleUploadSuccess} = props
  const [file, setFile] = React.useState(null)
  const [preview, setPreview] = React.useState(null)
  const [filename,setFilename] = React.useState(null)
  const [category, setCategory] = React.useState(null)
  const [title, setTitle] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [isUploading, setIsUploading] = React.useState(false)
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const { loadItems } = React.useContext(DataContext)

  const styled = {
    // Input text color
    // input: { color: 'black' }, 

    // Fieldset (border) styling
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        borderColor: 'gray' // Keep border white on hover
      },
    }
}

const uploadFile = async () => {
  if (!file) {
    alert('No file selected!');
    return;
  }
  if (!category) {
    alert('Category required!');
    return;
  }
  setIsUploading(true);
  setUploadSuccess(false);
  const formData = new FormData();
  formData.append("file", file);
  formData.append('title', title);
  formData.append('description', description);
  formData.append('category', category);
  try {
    await axios.post(url + "/uploadPostCard", formData);
    loadItems();
    setUploadSuccess(true);
    handleUploadSuccess()
  } catch (error) {
    alert('Upload failed');
    console.error(error);
  } finally {
    setIsUploading(false);
    setFile(null);
    setActiveStep(0); // Reset the stepper
  }
};

  const handleInputChange = (event) => {
		const inputImage = event.target.files[0];
		setFile(inputImage);
     // Generate image preview
     const reader = new FileReader();
     reader.onloadend = () => {
       setPreview(reader.result);
     };
     setFilename(inputImage.name)
     if (inputImage) {
       reader.readAsDataURL(inputImage);
     } else {
       setPreview(null);
     }
	};

  
  const handleNext = async () => {
    if (activeStep === 0 && !file) {
      alert('No file selected!');
      return;
    }
    if (activeStep === 2) {
      await uploadFile();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <HorizontalStepper activeStep={activeStep} handleNext={handleNext} handleBack={handleBack}>
      {activeStep === 0 && (
        <div>
          {!isUploading && (
            <div style={{width:'100%', textAlign:'center'}}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{margin:'auto'}}
              
            >
              Select File
              <VisuallyHiddenInput type="file" onChange={handleInputChange} />
            </Button>
            </div>
          )}
          <div>
            {/* <h4 style={{ textAlign: 'center' }}>{filename}</h4> */}
            {preview && (
              <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain', marginTop: '20px' }} />
            )}
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <div>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              alignItems: 'center',
              justifyContent: {
                xs: 'center',
                sm: 'center',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled"
              label="Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              sx={styled}
              InputLabelProps={{
                style: { color: 'gray' },
              }}
            />
            <TextField
              id="outlined-controlled"
              label="Category"
              value={category}
              required
              onChange={(event) => {
                setCategory(event.target.value);
              }}
              sx={styled}
              InputLabelProps={{
                style: { color: 'gray' },
              }}
            />
          </Box>
          <Box>
            <TextField
              fullWidth
              label="Description"
              id="fullWidth"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Box>
        </div>
      )}
       {activeStep === 2 && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          {isUploading ? (
            <CircularProgress />
          ) : (
            uploadSuccess && (
              <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
            )
          )}
        </div>
      )}
    </HorizontalStepper>
  );
}