import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from './url';

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const login = async (email, password) => {
    try {
      const response = await axios.post(url + '/login', { email, password }, { withCredentials: true });
      if (response.data.token) {
        setStatus('success');
        setErrorMessage('');
        navigate('/');
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
    }
  };

  const logout = async () => {
    try {
      const response = await axios.post(url + '/logout', {}, { withCredentials: true });
      if (response.data.message) {
        setStatus(null);
        navigate('/');
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const validateSession = async () => {
	  try {
		  const response = await axios.get(url + '/validate', { withCredentials: true });
      // console.log(response)
      if (response.data.success) {
			setStatus('success');
		} else {
			setStatus(null);
		}
    } catch (error) {
      console.error('Session validation failed', error);
    }
  };

  useEffect(() => {
    validateSession();
  }, []);

  return (
    <AuthContext.Provider value={{ status, login, logout, errorMessage }}>
      {children}
    </AuthContext.Provider>
  );
}
