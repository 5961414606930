import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import {Button} from '@mui/material'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate()
  const { login, errorMessage,status } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const styled = {
        // Input text color
        input: { color: 'white' }, 

        // Fieldset (border) styling
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white'
          },
          '&:hover fieldset': {
            borderColor: 'white' // Keep border white on hover
          },
        }
  }

  useEffect(() => {
    if(status === 'success') {
      navigate('/')
    }
  },[status])

  return (
    <div className='tf' style={{margin:'auto'}}> 
      <h2 className='loginText'>Login</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <Box
        component="form"
        sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                display: 'flex',
        flexDirection: {
          xs: 'column', // Stack vertically on mobile devices
          sm: 'row' // Default horizontal layout on larger screens
        },
        alignItems: 'center', // Center items vertically
        justifyContent: {
          xs: 'center', // Center items horizontally on mobile
          sm: 'flex-start' // Align items to start on larger screens
        },
            }}
            noValidate
            autoComplete="off"
        >
        <TextField
            id="outlined-controlled"
            label="Email"
            value={email}
            onChange={(event) => {
            setEmail(event.target.value);
            }}
            sx={styled}
            InputLabelProps={{
                style: { color: 'white' },
              }}
        />
         <TextField
            id="outlined-controlled"
            label="Password"
            value={password}
            onChange={(event) => {
                setPassword(event.target.value);
            }}
            sx={styled}
            InputLabelProps={{
                style: { color: 'white' },
              }}
        />
        </Box>
        <div className='button' style={{display:'flex', justifyContent:'center'}}>
            <Button type="submit" variant='contained' onClick={handleSubmit}>Login</Button>
        </div>
    </div>
  );
};

export default Login;
