import React, { useState, useContext } from 'react';
import PhotoList from '../components/PhotoList';
import Modal from '../components/Modal';
import { AuthContext } from '../AuthContext';
import { DataContext } from '../DataContext';
import CustomDrawer from '../components/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { itemData, category } = useContext(DataContext);
  const { status } = useContext(AuthContext);
  const navigate = useNavigate()

  const handleCategoryChange = (category) => {
    setSelectedCategory(category); // Update selectedCategory on change
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Filter the items based on the selected category
  const filteredItemData = selectedCategory
    ? itemData.filter(item => item.category === selectedCategory)
    : itemData;

  return (
    <div style={{minWidth:'70%'}}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h1 className='tf' onClick={() => navigate('/')}>POST SECRET</h1>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon sx={{color:'white'}} />
        </IconButton>
        <CustomDrawer
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          category={category}
          onCategoryChange={handleCategoryChange}
        />
      </div>
      {status && <Modal/>}
      <div><PhotoList itemData={filteredItemData} /></div>
    </div>
  );
}
