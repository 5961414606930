import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PhotoPopOver from '../components/PhotoPopOver';
import { DataContext } from '../DataContext';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList(props) {
  const { deleteCard } = React.useContext(DataContext)
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const {itemData } = props

  const handleOpen = (img) => {
    setSelectedImage(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null); // Optional: reset selected image on close
  };

  const calculateColsRows = (index) => {
    const cycleIndex = index % 8;
    switch (cycleIndex) {
      case 0:
        return { cols: 2, rows: 2 };
      case 1:
        return { cols: 1, rows: 1 };
      case 2:
        return { cols: 1, rows: 1 };
      case 3:
        return { cols: 2, rows: 1 };
      case 4:
        return { cols: 2, rows: 1 };
      case 5:
        return { cols: 2, rows: 2 };
      case 6:
        return { cols: 1, rows: 1 };
      case 7:
        return { cols: 1, rows: 1 };
      default:
        return { cols: 1, rows: 1 };
    }
  };

  return (
    <div>
      <ImageList
        // sx={{ minWidth: '100%' }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
      {itemData.map((item, index) => {
          const { cols, rows } = calculateColsRows(index);
          return (
            <ImageListItem
              key={index}
              cols={cols}
              rows={rows}
              onClick={() => handleOpen(item)}
            >
              <img
                {...srcset(item.url, 121, rows, cols)}
                alt={index}
                loading="lazy"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
      {open && (
        <PhotoPopOver 
        open={open} 
        handleClose={handleClose} 
        selectedImage={selectedImage} 
        deleteCard = {deleteCard}
        />
      )}
    </div>
  );
}

